<template>

    <swiper-slide class="h-full w-full overflow-auto no-scrollbar" style="width: 100%">

        <loader :loading="loading" />

        <div v-if="sortBy" class="h-full w-full absolute rounded-lg module p-2 flex flex-col z-40">

            <div class="h-10 flex flex-row justify-start items-center px-1">

                <span class="text-font-gray font-semibold mr-auto">SL</span>
                
                <i class="mdi mdi-filter-variant text-font-gray mr-1"></i>
                
                <multiselect class="w-32" :multiple="false" v-model="sortB" label="name" placeholder="Select option" :options="sortOptions" />

                <div class="h-6 w-6 rounded-md flex flex-col justify-center items-center cursor-pointer mx-1" :class="{'bg-aux-15 text-aux':all, 'bg-filters': !all}" @click="toggleSlSelectedItemsAll()">
                    <span class="text-3xs">ALL</span>
                </div>
            
            </div>

            <div class="flex-1 min-h-0 overflow-auto no-scrollbar">

                <sl-column @loadingSl="setLoading" :sortBy="sortB"
                :sortOrder="sortOrder" :mobile="true" @nextPage="nextPage"/>

            </div>

        </div>

    </swiper-slide>

</template>

<script>
const SlColumn = () => import('@/components/Globals/Pipeline/SlColumn.vue')
import { state } from '@/store';

export default {
    name: "SlideSL",
    props:['sortBy'],
    components: {
        SlColumn
    },
    data() {
        return {
            sortOptions: this.$optionsFilterPipeline,
            sortOrder: { name: 'ASC', value: 'asc' },
            loading: true,
            menu: false,
            filteredOportunities: true,
            all:false,
            sortB:null
        }
    },
    methods: {
        onPanUp(){ 
            this.menu = false 
        },
        onPanDown(){ 
            this.menu = true 
        },
        setLoading(val){ 
            this.loading = val 
        },
        nextPage(){
            this.$emit('nextPage')
        },
        toggleSlSelectedItemsAll(){

            this.all = !this.all

            if(!this.all){

                for (let index = 0; index < Object.keys(this.legendColors).length; index++) {
                    state.slSelectedItems = state.slSelectedItems.filter(item => item !== Object.keys(this.legendColors)[index])
                }

            } else {

                for (let index = 0; index < Object.keys(this.legendColors).length; index++) {  
                    state.slSelectedItems.push(Object.keys(this.legendColors)[index])
                }

            }

            if(this.all){
                this.$emit('nextPage')
            }

        }
    },
    watch:{
        sortB(){
            this.$emit('changeSortBy', this.sortB)
        }
    },
    computed:{
        legendColors(){ 
            return state.slLegendColors 
        },
    },
    mounted(){
        this.sortB = this.sortBy
    }
}

</script>